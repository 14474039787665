import { Button, SvgIconTypeMap, ButtonPropsColorOverrides } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { OverridableStringUnion } from '@mui/types';
import { fileListToArray } from '@imas/utils/files';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { FileImporterInput } from './FileImporterInput';
import { FileImportCallback, FileImporterRef } from './types';

//FileImporterProps interface
interface FileImporterProps {
    text?: string,
    icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>,
    size?: "medium" | "small" | "large",
    iconLocation?: "start" | "end" | "middle",
    disabled?: boolean,
    color?: OverridableStringUnion<'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning', ButtonPropsColorOverrides>,
    variant?: "text" | "outlined" | "contained",
    className?: string,
    fileTypeWhitelist?: string[],
    multiple?: boolean,
    capture?: boolean | "environment" | "user",
    onImport: FileImportCallback,
    height?: string,
}

const FileImporter = forwardRef<FileImporterRef, FileImporterProps>((props, ref) => {
    //component props
    const { text, size, iconLocation, disabled, color, variant, className, height } = props;
    const Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> = props.icon === undefined ? CloudUploadIcon : props.icon;

    //Rendered Button
    return (
        <Button
            disabled={disabled}
            color={color ?? "primary"}
            variant={variant ?? "outlined"}
            size={size}
            className={className}
            endIcon={iconLocation === "end" ? <Icon/> : null} 
            startIcon={(iconLocation === "start") || iconLocation === undefined ? <Icon/> : null}
            component={"label"}
            sx={height ? {height: height} : null}
        >
            {iconLocation === "middle" ? <Icon/> : null}
            {text ?? "Upload"}
            <FileImporterInput
                disabled={props.disabled}
                multiple={props.multiple}
                capture={props.capture}
                fileTypeWhitelist={props.fileTypeWhitelist} 
                onImport={props.onImport}
                ref={ref}
            />
        </Button>
    );
});


export { FileImporter };